.InfoLink {
    font-family: 'Overpass';
    font-size: 14px;
    background-color: #f5f7fa; /* grey */
    text-align: center;
    display: inline-block;

    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 30px;

    border-radius: 3px;
    transition: border .1s ease-in-out;
    border: 1px solid transparent;

    -webkit-animation: fadeInUpButton 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUpButton 2s; /* Firefox < 16 */
    -ms-animation: fadeInUpButton 2s; /* Internet Explorer */
    -o-animation: fadeInUpButton 2s; /* Opera < 12.1 */
    animation: fadeInUpButton 2s;
}

.InfoLink .glyphicon {
    font-size: 12px;
    padding-left: 10px;
}

.InfoLink:hover {
    cursor: pointer;
}

.InfoLink .button-description {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    padding-right: 15px;

    margin-right: 10px;

    color: #fff;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    display: inline-block;
}

@media (max-width: 576px) {
    .InfoLink .button-description {
        width: 100%;
    }
}

.InfoLink .description {
    margin-top: 10px;
    font-weight: 400;
    display: inline-block;
    text-align: center;
}
