.Footer {
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 20px;
    padding: 50px;
    background-color: #191d1f;
    text-align: left;
}

.Footer .contact {
    margin-bottom: 30px;
}

.Footer .SocialBar {
    margin-bottom: 30px;
}

.Footer .copyright {
    font-size: 14px;
    color: #646d78;
}

.Footer .contact h1 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    color: #a9b2bd;
}

.Footer .contact p {
    color: #646d78;
    font-size: 18px;
}

.Footer .description {
    font-size: 12px;
    color: #a9b2bd;
}

.Footer a {
    color: #fff
}
