body {
    margin: 0;
    padding: 0;
    font-family: "Overpass";
    font-size: 16px;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Overpass";
}

h1 {
    font-family: 'Overpass';
    font-weight: 400;
    font-size: 32px;
    color: #141413;
    margin-top: 0px;
}

h2 {
    font-family: 'Overpass';
    font-weight: 300;
    font-size: 18px;
    color: #AAB2BD;
}

h3 {
    font-family: 'Overpass';
    font-weight: 400;
    font-size: 18px;
    color: #141413;
}

p {
    font-family: 'Overpass';
    font-weight: 300;
    font-size: 14px;
    color: #141413;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
    font-size: 16px;
}

input[type=file] {
    width: 100%;
}


@-webkit-keyframes fadeInUpImage {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 10%, 0);
        transform: translate3d(0, 10%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes fadeInUpButton {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 20%, 0);
        transform: translate3d(0, 20%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes fadeInUpText {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -20%, 0);
        transform: translate3d(0, -20%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
