.HireMe {
    text-align: left;
}

.HireMe .Description {
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dde2e6;
}

.HireMe .Description p {
    font-size: 24px;
    color: #444444;

    -webkit-animation: fadeInUpText 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUpText 1s; /* Firefox < 16 */
    -ms-animation: fadeInUpText 1s; /* Internet Explorer */
    -o-animation: fadeInUpText 1s; /* Opera < 12.1 */
    animation: fadeInUpText 1s;
}

.HireMe .Services {
    margin-bottom: 40px;
    padding-top: 30px;
    border-top: 1px solid #dde2e6;
}

.HireMe .Services p {
    margin-bottom: 10px;
    color: #a9b2bd;

    -webkit-animation: fadeInUpText 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUpText 1s; /* Firefox < 16 */
    -ms-animation: fadeInUpText 1s; /* Internet Explorer */
    -o-animation: fadeInUpText 1s; /* Opera < 12.1 */
    animation: fadeInUpText 1s;
}

.HireMe h1 {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #a9b2bd;
    margin-bottom: 10px;

    -webkit-animation: fadeInUpText 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUpText 1s; /* Firefox < 16 */
    -ms-animation: fadeInUpText 1s; /* Internet Explorer */
    -o-animation: fadeInUpText 1s; /* Opera < 12.1 */
    animation: fadeInUpText 1s;
}

.HireMe h2 {
    font-size: 18px;
    font-weight: 400;
    color: #444444;
    display: inline-block;
    text-transform: uppercase;

    background-color: #ffe8d5;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
    margin-top: 20px;
    margin-bottom: 15px;

    -webkit-animation: fadeInUpText 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUpText 1s; /* Firefox < 16 */
    -ms-animation: fadeInUpText 1s; /* Internet Explorer */
    -o-animation: fadeInUpText 1s; /* Opera < 12.1 */
    animation: fadeInUpText 1s;
}

.HireMe .Services .glyphicon {
    display: inline;
    margin-right: 3px;
    font-size: 12px;
}

.HireMe h3 {
    color: #444444;
    display: inline;
    font-size: 24px;

    -webkit-animation: fadeInUpText 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUpText 1s; /* Firefox < 16 */
    -ms-animation: fadeInUpText 1s; /* Internet Explorer */
    -o-animation: fadeInUpText 1s; /* Opera < 12.1 */
    animation: fadeInUpText 1s;
}

.HireMe .InfoLink {
    margin-bottom: 30px;
}
