.Products {
}

.Product {
    margin-bottom: 30px;
}

.Products .Category {
    text-transform: uppercase;
    border: 1px solid black;
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    border-radius: 5px;
    margin-bottom: 8px;
}

.Products h1 {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #a9b2bd;
    margin-bottom: 30px;

    -webkit-animation: fadeInUpText 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUpText 1s; /* Firefox < 16 */
    -ms-animation: fadeInUpText 1s; /* Internet Explorer */
    -o-animation: fadeInUpText 1s; /* Opera < 12.1 */
    animation: fadeInUpText 1s;
}

.Products h2 {
    color: black;
    margin-bottom: 20px;
}

.Products .ProductList h1 {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    color: black;

    margin-bottom: 10px;

    -webkit-animation: fadeInUpText 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUpText 1s; /* Firefox < 16 */
    -ms-animation: fadeInUpText 1s; /* Internet Explorer */
    -o-animation: fadeInUpText 1s; /* Opera < 12.1 */
    animation: fadeInUpText 1s;
}

.Products .ProductList h2 {
    font-size: 14px;
    font-weight: 400;
    color: #a9b2bd;

    margin-top: 0px;
    margin-bottom: 10px;

    -webkit-animation: fadeInUpText 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUpText 1s; /* Firefox < 16 */
    -ms-animation: fadeInUpText 1s; /* Internet Explorer */
    -o-animation: fadeInUpText 1s; /* Opera < 12.1 */
    animation: fadeInUpText 1s;
}

a {
    text-decoration: none !important;
}

.Product img {
    cursor: pointer;
    opacity: 1;

    -webkit-animation: fadeInUpText 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUpText 1s; /* Firefox < 16 */
    -ms-animation: fadeInUpText 1s; /* Internet Explorer */
    -o-animation: fadeInUpText 1s; /* Opera < 12.1 */
    animation: fadeInUpText 1s;
}

.Product img:hover {
    opacity: .8;
    transition: all .5s ease-in-out;
}
