.YouTubeContainer h1 {
    margin-bottom: 20px;
    color: #a9b2bd;
    text-transform: uppercase;
    font-size: 18px;
}

.YouTubeContainer .Videos {
    text-align: center;
}

.YouTubeContainer .YouTube {
    margin-bottom: 25px;
}
