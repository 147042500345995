.Photography {
    text-align: center;

    -webkit-animation: fadeInUpImage 1; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUpImage 1s; /* Firefox < 16 */
    -ms-animation: fadeInUpImage 1s; /* Internet Explorer */
    -o-animation: fadeInUpImage 1s; /* Opera < 12.1 */
    animation: fadeInUpImage 1s;
}

.Photography h1 {
    text-align: left;;
    font-size: 18px;
    text-transform: uppercase;
    color: #a9b2bd;
    margin-bottom: 20px;

    -webkit-animation: fadeInUpText 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUpText 1s; /* Firefox < 16 */
    -ms-animation: fadeInUpText 1s; /* Internet Explorer */
    -o-animation: fadeInUpText 1s; /* Opera < 12.1 */
    animation: fadeInUpText 1s;
}

.Photography .react-photo-gallery--gallery {
    margin-bottom: 50px;
}

.Photography .Category-Links {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
    text-transform: 20px;
}

.Photography .Category-Links a {
    background-color: #f5f7fa;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 3px;
    margin-bottom: 20px;
}

.Photography .Category-Links a {
    margin-right: 10px;
    text-decoration: none;
    color: #444444;
}

.Photography .Category-Links .active {
    color: #ff4d4d;
    border: 1px solid #ff4d4d;
    font-weight: 600;
}

.Photography img:hover {
    -webkit-filter: brightness(65%);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
    cursor: pointer;
}

.Photography .MyGear {
    margin-top: 45px;
    text-align: center;
    text-transform: uppercase;
    border-top: 1px solid #dde2e6;
    padding-top: 20px;
}

.Photography .MyGear h1 {
    font-weight: 700;
    text-align: center;;
    font-size: 28px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: black;
    margin-bottom: 15px;
    -webkit-animation: fadeInUpText 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUpText 1s; /* Firefox < 16 */
    -ms-animation: fadeInUpText 1s; /* Internet Explorer */
    -o-animation: fadeInUpText 1s; /* Opera < 12.1 */
    animation: fadeInUpText 1s;
}

.Photography .MyGear h2 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #646d78;
    font-weight: 700;
}

.Photography .MyGear h3 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #646d78;
    font-weight: 400;
}

.Photography .InfoLink {
    margin-top: 30px;
}
