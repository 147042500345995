.Header {
    font-family: 'Overpass';
    font-weight: 600;
    position: relative;
    margin-bottom: 40px;

    -webkit-animation: fadeInDown 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInDown 2s; /* Firefox < 16 */
    -ms-animation: fadeInDown 2s; /* Internet Explorer */
    -o-animation: fadeInDown 2s; /* Opera < 12.1 */
    animation: fadeInDown 2s;
}

.Header .home {
    margin-bottom: 20px;
}

.Header .home a {
    font-weight: 400;
    font-size: 24px;
    color: black;
    text-decoration: none;
}

.Header .home:hover a {
    color: #ff4d4d;
    text-decoration: none;
    transition: all .5s ease-in-out;
}

.Header .nav-links {
    font-size: 18px;
}

.Header .nav-links a {
    display: inline-block;
    margin-right: 16px;
    color: #AAB2BD;
    text-decoration: none;
}

.Header .nav-links .active {
    color: #ff4d4d;
}

.Header .nav-links a:hover {
    color: #ff4d4d;
    text-decoration: none;
    transition: all .5s ease-in-out;
}

.Header .SocialBar {
    position: absolute;
    top: 3px;
    right: 0px;
    font-size: 24px;
}
