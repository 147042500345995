@media (min-width: 0px) {
    .container {
        margin: 15px;
    }
}

@media (min-width: 576px) {
    .container {
        margin: 30px;
    }
}

@media (min-width: 768px) {
    .container {
        width: 768px;
        margin: auto;
        margin-top: 50px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 768px;
        margin: auto;
        margin-top: 50px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 768px;
    }
}


.App .container {
    height: 300px;
    display: flex; /*added*/
}

html, body {
    height: 100%;
}
