.Home {
    text-align: center;
}

.Home h1 {
    font-weight: 400;
    font-size: 28px;
    color: #141413;
    margin-bottom: 20px;

    -webkit-animation: fadeInUpText 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUpText 1s; /* Firefox < 16 */
    -ms-animation: fadeInUpText 1s; /* Internet Explorer */
    -o-animation: fadeInUpText 1s; /* Opera < 12.1 */
    animation: fadeInUpText 1s;
}

.Home p {
    margin-bottom: 30px;
    font-size: 24px;
    color: #AAB2BD;
}

.Home img {

    margin-bottom: 25px;

    -webkit-animation: fadeInUpImage 1; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeInUpImage 1s; /* Firefox < 16 */
    -ms-animation: fadeInUpImage 1s; /* Internet Explorer */
    -o-animation: fadeInUpImage 1s; /* Opera < 12.1 */
    animation: fadeInUpImage 1s;
}

.Home img:hover {
    -webkit-filter: brightness(65%);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
    cursor: pointer;
}
