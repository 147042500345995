.SocialBar {
    font-size: 24px;
}

.SocialBar .SocialIcon {
    display: inline-block;
    font-size: 24px;
}

.SocialBar .SocialIcon:hover {
    color: #67696c;
    text-decoration: none;
    transition: all .5s ease-in-out;
}
